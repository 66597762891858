import React from "react"
import { Container, Row } from "reactstrap"

//Import Components
import CardBox from "./card-box"

const CardsMini = () => {
  const coins = [
    {
      title: "Riduci la dipendenza dai portali di lavoro",
      color: "warning",
      icon: "mdimdi-lock-pattern",
      value: "Trasparenza",
      rate: "0.0012.23 ( 0.2 % )",
      isIncrease: true,
    },
    {
      title: "Proteggi i tuoi asset",
      color: "primary",
      icon: "mdi mdi-account-hard-hat",
      value: "Anonimato",
      rate: "004.12 ( 0.1 % )",
      isIncrease: true,
    },
    {
      title: "Aumenta i tuoi profitti",
      color: "info",
      icon: "mdi mdi-stairs-up",
      value: "Efficienza",
      rate: "0.0001.12 ( 0.1 % ) ",
      isIncrease: true,
    },
  ]

  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
              <CardBox coins={coins} />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
